.titulo {
    font-family: 'Gotham', sans-serif;
    font-weight: bold;
    font-size: 70px;
    color: #936BE1;


}

.subtitulo {
    font-family: 'Gotham Black', sans-serif;
    font-weight: bold;
    color: #402399;
    font-size: 70px;

}

p {
    font-family: 'Gotham ', sans-serif;
    color: #6b6b6b;
    text-align: justify;

}


.digio {
    position: absolute;
    z-index: 2;
    left: 50%;
    top: 80px;
    transform: translateX(-50%);
    animation: animate 3s infinite ease alternate;
}

@keyframes animate {
    to {
        transform: scale(50%, 50%)
    }

}

.creadora {
    position: absolute;
    z-index: 1;
    left: 50%;
    top: 150px;
    width: 240px;
    transform: translateY(-50%);
    transform: translateX(-50%);
    animation: animatep 3s infinite ease alternate;
    animation-delay: 3s;

}

@keyframes animatep {
    to {
        transform: scale(1%, 1%)
    }

}

@media (max-width: 767px) {
    .digio {
        position: absolute;
        z-index: 2;
        left: 30%;
        top: 10px;
        transform: translateX(-25%);
        animation: animate 3s infinite ease alternate;
    }

    .creadora {
        position: absolute;
        z-index: 1;
        left: 40%;
        top: 80px;
        width: 240px;
        transform: translateY(-50%);
        transform: translateX(-50%);
        animation: animatep 3s infinite ease alternate;
        animation-delay: 3s;
    }

    canvas {
        display: block;
        width: 370.4px;
        height: 320px;
        touch-action: none;
    }

    .container-footer {
        padding-top: 35px;

    }
    .titulo {
        font-family: 'Gotham', sans-serif;
        font-weight: bold;
        font-size: 60px;
        color: #936BE1;
    }
    .subtitulo {
        font-family: 'Gotham Black', sans-serif;
        font-weight: bold;
        color: #402399;
        font-size: 60px;
    }

    


}