
.remove-style, .contenedor {
    all:unset ;
}
.parrafo {
    font-family: 'Gotham', sans-serif;
    color: #ffffff;
    text-align: justify;
}
.titulos{
    font-family: 'Gotham Bold', sans-serif;
    color: #ffffff;
    text-align: justify;
}


