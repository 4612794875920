
.link{
    font-family: 'Gotham Book', sans-serif;
    color: #5E17EB;
}
.link:after {
    display:block;
    content: '';
    border-bottom: solid 3px #5E17EB;  
    transform: scaleX(0);  
    transition: transform 250ms ease-in-out;
}
.link:hover:after { transform: scaleX(1); }

.btn{
    font-family: 'Gotham Black', sans-serif;
    background-color: #5E17EB;
}
.btn:hover{
    background: #6c55b2;
}