.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


.form_main {
  width: 280px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: rgb(255, 255, 255);
  padding: 30px 30px 30px 30px;
  border-radius: 30px;
  box-shadow: 0px 0px 40px rgba(0, 0, 0, 0.062);
}

.heading {
  font-size: 2.5em;
  color: #2e2e2e;
  font-weight: 700;
  margin: 15px 0 30px 0;
}

.inputContainer {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.inputIcon {
  position: absolute;
  left: 10px;
}

.inputField {
  width: 100%;
  height: 40px;
  background-color: transparent;
  border: none;
  border-bottom: 2px solid rgb(173, 173, 173);
  border-radius: 30px;
  margin: 10px 0;
  color: black;
  font-size: .8em;
  font-weight: 500;
  box-sizing: border-box;
  padding-left: 30px;
}

.inputField:focus {
  outline: none;
  border-bottom: 2px solid rgb(199, 114, 255);
}

.inputField::placeholder {
  color: rgb(80, 80, 80);
  font-size: 1em;
  font-weight: 500;
}

#button {
  position: relative;
  width: 100%;
  border: 2px solid #8000ff;
  background-color: #8000ff;
  height: 40px;
  color: white;
  font-size: .8em;
  font-weight: 500;
  letter-spacing: 1px;
  border-radius: 30px;
  margin: 10px;
  cursor: pointer;
  overflow: hidden;
}

#button::after {
  content: "";
  position: absolute;
  background-color: rgba(255, 255, 255, 0.253);
  height: 100%;
  width: 150px;
  top: 0;
  left: -200px;
  border-bottom-right-radius: 100px;
  border-top-left-radius: 100px;
  filter: blur(10px);
  transition-duration: .5s;
}

#button:hover::after {
  transform: translateX(600px);
  transition-duration: .5s;
}

.signupContainer {
  margin: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
}

.signupContainer p {
  font-size: .9em;
  font-weight: 500;
  color: black;
}

.signupContainer a {
  font-size: .7em;
  font-weight: 500;
  background-color: #2e2e2e;
  color: white;
  text-decoration: none;
  padding: 8px 15px;
  border-radius: 20px;
}

.spinner {
  --size-of-spinner: 50px;
  --spinner-border-width: 4px;
  --spinner-color: #5E17EB;
  --circle-color: #8bc34a24;
  --speed-of-animation: 2s;
  --scale: 1.3;
  width: var(--size-of-spinner);
  height: var(--size-of-spinner);
  background: var(--circle-color);
  border-radius: 50%;
  position: relative;
 }
 
 .spinner::after {
  content: "";
  display: block;
  position: absolute;
  border-radius: 50%;
  inset: 0;
  border: var(--spinner-border-width) solid var(--spinner-color);
  border-left-color: transparent;
  border-right-color: transparent;
  animation: spinny var(--speed-of-animation) linear infinite;
 }
 
 @keyframes spinny {
  0% {
   transform: rotate(0deg) scale(1)
  }
 
  50% {
   transform: rotate(45deg) scale(var(--scale))
  }
 
  100% {
   transform: rotate(360deg) scale(1)
  }
 }


 .btn-no-color {
  background-color: transparent !important;
  border-color: #fff !important; /* Ajusta el color del borde según tus necesidades */
  color: #fff !important; /* Ajusta el color del texto según tus necesidades */
}

